import React, { useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import Fader from '../../components/Loaders/Fader';

import useOutsideClick from '../../../util/useOutsideClick';
import { formatNumber, formatNumberWithCommas } from '../../../util/HelperFunctions';
import { partyColors } from '../../../util/constants/AppConstants';

import './Elections.scss';

const getPartyColor = (party) => {
  const partyInfo = partyColors.find((elem) => elem.party === party);
  return partyInfo ? partyInfo.color : '';
};

const Elections = () => {
  const [changeInfo, setChangeInfo] = useState(false);
  const [swingInfo, setSwingInfo] = useState(false);

  const changeRef = useRef();
  const changeIconRef = useRef();
  const swingRef = useRef();
  const swingIconRef = useRef();

  const { district } = useOutletContext();

  useOutsideClick(changeRef, changeIconRef, changeInfo, () => setChangeInfo(false));
  useOutsideClick(swingRef, swingIconRef, swingInfo, () => setSwingInfo(false));

  const toggleState = (setState) => {
    setState((prevState) => !prevState);
  };

  return (
    <div className="platform-frame">
      <div className="elections">
        {district ? (
          <>
            <div className="pfr-header">
              <div className="dashboard-title">{`${district.election}: ${district.district}`}</div>
            </div>
            <div className="chart-component">
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th>Party</th>
                    <th>Candidate</th>
                    <th>Votes</th>
                    <th>%</th>
                    <th className="info-cell">
                      ±%
                      <div ref={changeIconRef} className="info-icon" onClick={() => toggleState(setChangeInfo)}>
                        i
                      </div>
                      <div className={`info-box ${changeInfo ? '' : 'hidden'}`} ref={changeRef}>
                        <div className="ii-copy">
                          <div className="iic-exp">Change in percentage value since previous election</div>
                        </div>
                      </div>
                    </th>
                    {district.expenseLimit && <th>Expenditures</th>}
                  </tr>
                </thead>
                <tbody>
                  {district.candidates
                    .sort((a, b) => b.votes - a.votes)
                    .map((candidate, index) => (
                      <tr key={index}>
                        <td className="party__name">
                          <div className="party__color" style={{ background: getPartyColor(candidate.party) }}></div>
                          {candidate.party}
                        </td>
                        <td className={index === 0 ? 'bold' : ''}>{candidate.candidate}</td>
                        <td>{formatNumberWithCommas(candidate.votes)}</td>
                        <td>{formatNumber(candidate.percent)}</td>
                        <td>
                          {candidate.percentChange
                            ? `${candidate.isChangePositive ? '+' : '-'}${formatNumber(candidate.percentChange)}`
                            : ''}
                        </td>
                        {district.expenseLimit && <td>{`$${formatNumberWithCommas(candidate.expenditures)}`}</td>}
                      </tr>
                    ))}
                  <tr className="total">
                    <td colSpan={2}>{`Total valid votes${district.expenseLimit ? '/Expense limit' : ''}`}</td>
                    <td>{formatNumberWithCommas(district.validVotes.total)}</td>
                    <td>{formatNumber(district.validVotes.percent)}</td>
                    <td>
                      {district.validVotes.percentChange
                        ? `${district.validVotes.isChangePositive ? '+' : '-'}${formatNumber(district.validVotes.percentChange)}`
                        : ''}
                    </td>
                    {district.expenseLimit && <td>{`$${formatNumberWithCommas(district.expenseLimit)}`}</td>}
                  </tr>
                  <tr className="total">
                    <td colSpan={2}>Total rejected, unmarked, and declined ballots</td>
                    <td>{formatNumberWithCommas(district.rejectedVotes.total)}</td>
                    <td>{district.rejectedVotes.percent ? formatNumber(district.rejectedVotes.percent) : ''}</td>
                    <td>
                      {district.rejectedVotes.percentChange
                        ? `${district.rejectedVotes.isChangePositive ? '+' : '-'}${formatNumber(district.rejectedVotes.percentChange)}`
                        : ''}
                    </td>
                    {district.expenseLimit && <td></td>}
                  </tr>
                  <tr className="total">
                    <td colSpan={2}>Turnout</td>
                    <td>{formatNumberWithCommas(district.turnout.total)}</td>
                    <td>{formatNumber(district.turnout.percent)}</td>
                    <td>
                      {district.turnout.percentChange
                        ? `${district.turnout.isChangePositive ? '+' : '-'}${formatNumber(district.turnout.percentChange)}`
                        : ''}
                    </td>
                    {district.expenseLimit && <td></td>}
                  </tr>
                  <tr className="total">
                    <td colSpan={2}>Eligible voters</td>
                    <td>{formatNumberWithCommas(district.eligibleVoters)}</td>
                    <td></td>
                    <td></td>
                    {district.expenseLimit && <td></td>}
                  </tr>
                  <tr>
                    <td colSpan={2} className="party__name">
                      <div className="party__color" style={{ background: getPartyColor(district.winner) }}></div>
                      {district.winner}
                      {district.winner !== district.prevWinner ? (
                        <>
                          <span className="bold"> gain</span> from {district.prevWinner}
                        </>
                      ) : (
                        <span className="bold"> hold</span>
                      )}
                    </td>
                    <td colSpan={2} className="swing info-cell">
                      Swing
                      <div ref={swingIconRef} className="info-icon" onClick={() => toggleState(setSwingInfo)}>
                        i
                      </div>
                      <div className={`info-box ${swingInfo ? '' : 'hidden'}`} ref={swingRef}>
                        <div className="ii-copy">
                          <div className="iic-exp">
                            An electoral swing analysis (or swing) shows the extent of change in voter support,
                            typically from one election to another, expressed as a positive or negative percentage
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {district.swing.percentChange
                        ? `${district.swing.isChangePositive ? '+' : '-'}${formatNumber(district.swing.percentChange)}`
                        : ''}
                    </td>
                    {district.expenseLimit && <td></td>}
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default Elections;
