import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import SocialPosts from '../../Social/SocialPosts';
import Fader from '../../Loaders/Fader';

import { getTopSocialPosts } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import './TopSocialPosts.scss';

const TopSocialPosts = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [currentSource, setCurrentSource] = useState('twitter');
  const [postsData, setPostsData] = useState({
    forums: [],
    instagram: [],
    twitter: [],
    tiktok: [],
    reddit: [],
  });

  const handleChangeSource = (source) => {
    setCurrentSource(source);
  };

  const handleGetTopSocialPosts = async (id, userId) => {
    setLoading(true);

    const sources = {
      forums: 2,
      instagram: 3,
      reddit: 4,
      tiktok: 5,
      twitter: 6,
    };

    const requests = Object.keys(sources).map((source) => getTopSocialPosts(config, id, userId, sources[source]));

    const responses = await Promise.all(requests);
    const newPostsData = {};

    responses.forEach((res, index) => {
      const key = Object.keys(sources)[index];
      if (res.status === 'success') {
        newPostsData[key] = res.data;
      } else {
        console.log(`ERROR: Grabbing Top Posts for ${key}`);
        // Return an empty array for error handling purposes
        newPostsData[key] = [];
      }
    });

    setPostsData(newPostsData);
    setLoading(false);
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetTopSocialPosts(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  return (
    <div className="top-posts chart-component">
      <div className="chart-title align-left">Top Social Posts</div>
      <div className="sentiment-source-bar">
        {['forums', 'instagram', 'twitter', 'tiktok', 'reddit'].map((source) => {
          let displayName = source.charAt(0).toUpperCase() + source.slice(1);
          if (source === 'twitter') {
            displayName = 'X (Twitter)';
          }
          return (
            <div
              key={source}
              className={currentSource === source ? 'source-item selected' : 'source-item'}
              onClick={() => handleChangeSource(source)}
            >
              {displayName}
            </div>
          );
        })}
      </div>
      {!loading ? (
        postsData[currentSource].length ? (
          <div className="top-posts-list">
            <PerfectScrollbar
              options={{
                wheelPropagation: true,
                autoHide: false,
                wheelSpeed: 0.7,
              }}
            >
              <div className="top-posts-wrap">
                {postsData[currentSource].map((post, index) => (
                  <SocialPosts key={index} data={post} />
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        ) : (
          <div className="no-data">No Data Available. Please Check Back Shortly.</div>
        )
      ) : (
        <div className="sentiment-charts">
          <Fader />
        </div>
      )}
    </div>
  );
};

export default TopSocialPosts;
