import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import WardsMap from '../../components/WardsMap/WardsMap';
import Fader from '../../components/Loaders/Fader';
import PdfToImage from '../../../util/PdfToImage';
import Tooltip from '../../components/Tooltip/Tooltip';

import './Map.scss';

const Map = () => {
  const [mapType, setMapType] = useState('');

  const { district } = useOutletContext();

  useEffect(() => {
    if (district) {
      if (district.district.startsWith('Brampton')) {
        setMapType('wards');
      } else if (district.atlasMapKey) {
        setMapType('atlas');
      } else if (district.detailedMapKey) {
        setMapType('detailed');
      }
    }
  }, [district]);

  return (
    <div className="platform-frame">
      <div className="elections map">
        {district ? (
          <>
            <div className="pfr-header">
              <div className="dashboard-title">{`Electoral ${district.district} Map`}</div>
            </div>
            <ul className="map__tabs">
              {district.district.startsWith('Brampton') && (
                <li className="map__tab">
                  <button
                    type="button"
                    className={`map__btn ${mapType === 'wards' ? 'active' : ''}`}
                    onClick={() => setMapType('wards')}
                  >
                    Wards Map
                  </button>
                </li>
              )}
              {district.atlasMapKey && (
                <li className="map__tab">
                  <button
                    type="button"
                    className={`map__btn ${mapType === 'atlas' ? 'active' : ''}`}
                    onClick={() => setMapType('atlas')}
                  >
                    Atlas Map
                  </button>
                </li>
              )}
              {district.detailedMapKey && (
                <li className="map__tab">
                  <button
                    type="button"
                    className={`map__btn ${mapType === 'detailed' ? 'active' : ''}`}
                    onClick={() => setMapType('detailed')}
                  >
                    Overview Detailed Wall Map
                  </button>
                </li>
              )}
            </ul>
            <div className="chart-component">
              {mapType === 'wards' ? (
                <WardsMap />
              ) : (
                <PdfToImage pdfKey={mapType === 'atlas' ? district.atlasMapKey : district.detailedMapKey} />
              )}
            </div>
          </>
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default Map;
